// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-about-us-js": () => import("./../../../src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-en-tags-js": () => import("./../../../src/pages/en/tags.js" /* webpackChunkName: "component---src-pages-en-tags-js" */),
  "component---src-pages-pt-404-js": () => import("./../../../src/pages/pt/404.js" /* webpackChunkName: "component---src-pages-pt-404-js" */),
  "component---src-pages-pt-contato-js": () => import("./../../../src/pages/pt/contato.js" /* webpackChunkName: "component---src-pages-pt-contato-js" */),
  "component---src-pages-pt-index-js": () => import("./../../../src/pages/pt/index.js" /* webpackChunkName: "component---src-pages-pt-index-js" */),
  "component---src-pages-pt-politica-de-privacidade-js": () => import("./../../../src/pages/pt/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-pt-politica-de-privacidade-js" */),
  "component---src-pages-pt-quem-somos-js": () => import("./../../../src/pages/pt/quem-somos.js" /* webpackChunkName: "component---src-pages-pt-quem-somos-js" */),
  "component---src-pages-pt-search-js": () => import("./../../../src/pages/pt/search.js" /* webpackChunkName: "component---src-pages-pt-search-js" */),
  "component---src-pages-pt-tags-js": () => import("./../../../src/pages/pt/tags.js" /* webpackChunkName: "component---src-pages-pt-tags-js" */),
  "component---src-templates-en-category-js": () => import("./../../../src/templates/en/Category.js" /* webpackChunkName: "component---src-templates-en-category-js" */),
  "component---src-templates-en-post-js": () => import("./../../../src/templates/en/Post.js" /* webpackChunkName: "component---src-templates-en-post-js" */),
  "component---src-templates-en-tag-js": () => import("./../../../src/templates/en/Tag.js" /* webpackChunkName: "component---src-templates-en-tag-js" */),
  "component---src-templates-pt-category-js": () => import("./../../../src/templates/pt/Category.js" /* webpackChunkName: "component---src-templates-pt-category-js" */),
  "component---src-templates-pt-post-js": () => import("./../../../src/templates/pt/Post.js" /* webpackChunkName: "component---src-templates-pt-post-js" */),
  "component---src-templates-pt-tag-js": () => import("./../../../src/templates/pt/Tag.js" /* webpackChunkName: "component---src-templates-pt-tag-js" */)
}

